import { useNavigate } from "react-router";
import { useEffect } from "react";
import "./404Style.scss";
const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/error");
  }, []);
  return (
    <div className="container">
      <div className="banner">404 : Sorry, this page isn't available!</div>
    </div>
  );
};
export default NotFoundPage;

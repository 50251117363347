import downloadIcon from "../../assets/icons/download.png";
import addIcon from "../../assets/icons/add.png";
import { useDispatch } from "react-redux";
import { showBanner } from "store/actions/pin.action";
import React, { FC } from "react";
import "./popupStyle.scss";
import { Trans, useTranslation } from "react-i18next";
interface ModalInterface {
  isIphone: boolean;
}

const DownloadBanner: FC<ModalInterface> = ({ isIphone }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = (e: any) => {
    e.stopPropagation();
    dispatch(showBanner(false));
  };
  return (
    <div
      className={"containerModal"}
      onClick={() => dispatch(showBanner(false))}
    >
      <div className={"modalBox"}>
        <div className={"titleContainer"}>
          <div>{t("Installazione")}</div>
          <div onClick={(e) => closeModal(e)}>X</div>
        </div>
        <div className={"bottomModal"}>
          {isIphone ? (
            //ios modal
            <Trans
              components={{
                download: (
                  <img
                    src={downloadIcon}
                    width={20}
                    height={20}
                    style={{ marginBottom: -5 }}
                  />
                ),
                add: <img src={addIcon} width={15} height={15} />,
              }}
            >
              TocaPoi
            </Trans>
          ) : (
            //android modal
            <div className={"contentContainer"}>
              {t("AggungiHome")}!
              <button
                name="add-button"
                className={"openAlert"}
                id={"installBtn"}
              >
                + Add
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DownloadBanner;
// Tocca e poi icon <strong>'Aggiungi icon a Home'</strong>

import { ManifestType } from "types/manifest.type";

export const manifestConfiguration = (data: ManifestType) => {
  const manifestData = {
    short_name: data.shortName,
    name: data.name,
    icons: [
      {
        src: `${process.env.REACT_APP_BASE_URL}museum.ico`,
        sizes: "64x64 32x32 24x24 16x16",
        type: "image/x-icon",
      },
      {
        src: `${process.env.REACT_APP_BASE_URL}museum-192.png`,
        type: "image/png",
        sizes: "192x192",
      },
      {
        src: `${process.env.REACT_APP_BASE_URL}museum-512.png`,
        type: "image/png",
        sizes: "512x512",
      },
    ],
    start_url: process.env.REACT_APP_BASE_URL,
    display: "standalone",
    theme_color: "#000000",
    background_color: "#ffffff",
  };

  const stringManifest = JSON.stringify(manifestData);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);

  return manifestURL;
};

import { Route, Routes } from "react-router-dom";
import Home from "../pages/homePage";

import PrivateRoute from "./privateRoute";
import NotFoundPage from "pages/404/404";
import NotAllowed from "pages/notAllowed/notAllowed";

const routes: any = [
  {
    exact: true,
    path: "/",
    layout: <Home />,
    private: true,
  },
  {
    exact: true,
    path: "/noAccess",
    layout: <NotAllowed />,
  },
  {
    path: "/error",
    layout: <NotFoundPage />,
  },
];

export function RoutesApp() {
  return (
    <Routes>
      {routes.map((route: any) => (
        <Route
          key={route.path}
          path={route.path}
          element={<PrivateRoute component={route.layout} path={route.path} />}
        />
      ))}
      <Route path={"*"} element={<NotFoundPage />} />
    </Routes>
  );
}

import { AnyAction, combineReducers } from "redux";
import Pin from "./pin.reducer";
import Iframe from "./iframe.reducer";
import BannerValue from "./banner.reducer";
import Manifest from "./manifest.reduces";
const rootReducer = combineReducers({
  pin: Pin,
  iframe: Iframe,
  bannerValue: BannerValue,
  manifest: Manifest,
});

export type RootState = ReturnType<typeof rootReducer>;
// @ts-ignore
export default (state: any, action: AnyAction) => rootReducer(state, action);

import "./notAllowed.scss";

const NotAllowed = () => {
  return (
    <div className="container">
      <div className="banner">Sorry, you don't have access to this page</div>
    </div>
  );
};

export default NotAllowed;
